import React from "react";
import { Link } from "react-router-dom";
import TableWrapper from "../common/TableWrapper";
import Button from "../common/Button";
import styles from "./TableStyles.module.scss";

function UsersTable({ data, loading, editUser, deleteUser, onPaginationChange, pagination, hidePagination }) {
  console.log(data)
  const columns = [
    {
      header: "User ID",
      render: (e) => (
        <Link className={styles.link} to={`/users/${e.id}`}>
          {e.id}
        </Link>
      ),
      options: {
        width: 150,
      },
    },
    {
      header: "User",
      render: (e) => <b>{e.firstName + " " + e.lastName}</b>,
      options: {
        width: 250,
      },
    },
    {
      header: "Email",
      dataKey: "email",
      options: {
        width: 350,
      },
    },
    {
      header: "User phone",
      render: (e) => <>{e?.phone || "//"}</>,
      options: {
        width: 350,
      },
    },

    {
      header: "Company spending limint",
      render: (e) => <>{e?.company_user_info?.spending_credit_limit || 0 + " RSD"}</>,
      options: {
        width: 250,
      },
    },
    {
      header: "Spent this month",
      render: (e) => <>{e?.company_user_info?.credit_spent || 0 + " RSD"}</>,
      options: {
        width: 250,
      },
    },
    {
      header: "Actions",
      options: {
        fixed: "right",
        width: 170,
      },
      render: (e) => (
        <div className={styles.actionButtonWrap}>
          <Button className={styles.button} variant="white" onClick={() => editUser(e)}>
            Edit
          </Button>
          <Button className={styles.button} variant="white" onClick={() => deleteUser(e)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const activePage = data?.current_page;
  const totalElements = data?.total;
  const pageSize = pagination?.size || data?.per_page;

  return (
    <TableWrapper
      totalElements={totalElements}
      pageSize={pageSize}
      activePage={activePage}
      hidePagination={hidePagination}
      onChange={onPaginationChange}
      loading={loading}
      columns={columns}
      data={data?.data || []}
    />
  );
}

export default UsersTable;
